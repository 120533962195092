<div class="text-center mv-auto">
	<p>Please start over</p>
</div>

<button
	mat-flat-button
	class="bp-round-button mt-auto w-100"
	type="submit"
	color="primary"
	(click)="introFacade.gotoLoginPage()"
>
	Login
</button>
